.wrapper {
  display: inline-block;
  width: max-content;
}

.container {
  position: relative;
}

.content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  white-space: nowrap;
}

.promocodeWrapper {
  display: flex;
  align-items: center;
}

.icon {
  background-color: transparent;
}

.primary {
  .content {
    gap: max(10px, 0.52vw);
    padding: max(5px, 0.26vw) max(15px, 0.78125vw);

    font-size: max(35px, 1.82vw);

    background: #0000004d;

    border-radius: max(50px, 2.6vw);
    cursor: pointer;

    transition: background-color 100ms ease-out;
  }

  .promocodeWrapper {
    gap: max(10px, 0.52vw);

    font-weight: 500;
  }

  .icon {
    width: max(30px, 1.5625vw);
    height: max(30px, 1.5625vw);
  }

  &.copied .content {
    background-color: #00000080;
  }
}

.secondary {
  .content {
    gap: max(10px, 0.52vw);

    font-weight: 500;
    font-size: max(22px, 1.145vw);
  }

  .promocodeWrapper {
    gap: max(5px, 0.26vw);
    padding: max(5px, 0.26vw) max(10px, 0.52vw);

    background-color: #ffffff99;
    border-radius: max(50px, 2.6vw);
    cursor: pointer;

    transition: background-color 100ms ease-out;
  }

  .icon {
    width: max(30px, 1.5625vw);
    height: max(30px, 1.5625vw);
  }

  &.copied {
    .icon {
      color: var(--color-green);
    }
  }
}

@media (hover: hover) {
  .primary:hover {
    .content {
      background-color: #00000080;
    }
  }

  .secondary {
    .promocodeWrapper:hover {
      background-color: #ffffff;
    }
  }
}

@media (--desktop-m) {
  .primary {
    .content {
      font-size: 25px;
    }
  }

  .secondary {
    .content {
      font-size: 18px;
    }

    .icon {
      width: 25px;
      height: 25px;
    }
  }
}

@media (--mobile-m) {
  .primary {
    .content {
      gap: 5px;

      font-size: 18px;
    }

    .promocodeWrapper {
      gap: 5px;
    }

    .icon {
      width: 25px;
      height: 25px;
    }
  }

  .secondary {
    .content {
      font-size: 16px;
    }
  }
}
