.section {
  overflow: hidden;
}

.wrapperGallery {
  composes: main from 'containers';
  composes: cropped from 'containers';

  margin: var(--space-l) auto 0;
}

.gallery {
  margin: 0 var(--space-s-neg);
}

.slide {
  box-sizing: content-box;

  padding: 0 var(--space-s);
}

.progressBar {
  margin-top: var(--space-l);
}

@media (--desktop) {
  .gallery {
    margin: 0 var(--space-xs-neg);
  }

  .slide {
    padding: 0 var(--space-xs);
  }
}
