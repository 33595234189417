.block {
  height: auto;

  opacity: 1;

  transition: opacity 0.3s ease-out;
}

.inlineParagraph,
.inlineParagraph > p {
  display: inline;
}

.hidden {
  height: 0;
  overflow: hidden;

  opacity: 0;
}

.button {
  display: inline;

  color: var(--color-dark-gray);

  cursor: pointer;
}

.chevron {
  width: 10px;
  margin-left: var(--space-xxs);

  transition: 0.5s;
}

.active {
  transform: rotate(180deg);
}
