.list {
  composes: main from 'containers';

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--space-l);
}

.galleryContainer {
  composes: main from 'containers';

  display: none;

  overflow: hidden;
}

.gallery {
  margin: 0 var(--space-xs-neg);
}

.gallery .banner {
  width: 460px;
  padding: 0 var(--space-xs);
}

.track {
  margin-top: var(--space-l);
}

@media (--desktop) {
  .galleryContainer {
    display: block;
  }

  .list {
    display: none;
  }
}

@media (--mobile-m) {
  .gallery .banner {
    width: 350px;
  }
}

@media (--mobile) {
  .gallery .banner {
    width: 310px;
  }
}
