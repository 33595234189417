.iksweb {
  width: 60%;
  margin-top: var(--space-n);

  border: none;
  border-collapse: collapse;
  border-spacing: 0;

  & tbody {
    display: flex;
  }

  & tr {
    display: flex;
    flex-direction: column;
  }

  & td {
    padding: var(--space-m);

    font-size: var(--size-n);
    text-align: center;

    border: 1px solid var(--color-medium-gray);

    &:first-child {
      height: 48px;

      color: var(--color-dark-gray);
    }

    &:last-child {
      font-weight: 500;
    }
  }
}

@media (--desktop-m) {
  .iksweb {
    width: 80%;
  }
}

@media (--desktop) {
  .iksweb {
    width: 100%;

    & td {
      height: 48px;
    }
  }
}

@media (--mobile-m) {
  .iksweb {
    & td {
      padding: var(--space-s);
    }
  }
}

@media (--mobile) {
  .iksweb {
    & tbody {
      flex-wrap: wrap;
    }

    & tr {
      width: 50%;

      &:last-child {
        width: 100%;
      }
    }
  }
}
