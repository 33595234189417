.wrapperGallery {
  display: grid;
  grid-template-columns: repeat(var(--count), auto);

  gap: var(--space-s);
}

.photoWrapper {
  position: relative;

  cursor: pointer;
}

.photo {
  width: 100%;
  aspect-ratio: '1:1';

  border-radius: 5px;
}

.cnPhoto {
  border-radius: 5px;
}

.lastPhoto {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.background {
  background-color: var(--color-black);
  opacity: 0.3;
}

.countRest {
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--color-white);

  font-weight: 500;
  font-size: var(--size-b);
  line-height: 1.5;
}

@media (--mobile-m) {
  .countRest {
    font-size: var(--size-xl);
  }
}

@media (--mobile) {
  .wrapperGallery {
    gap: var(--space-xs);
  }
}
