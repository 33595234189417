.card {
  position: relative;

  &.promo {
    .actualPrice {
      color: var(--color-red);
    }
  }
}

.img {
  position: relative;
}

.creditTab {
  position: absolute;
  top: var(--space-s);
  left: var(--space-s);
  z-index: 3;

  opacity: 0;

  transition: opacity 200ms ease-in-out;
  &.yandexCreditTab {
    opacity: 1;
  }
}

.image {
  border-radius: 5px;
  aspect-ratio: '13.585:9';

  &.landscape {
    aspect-ratio: '13.585:9';
  }

  &.portrait {
    aspect-ratio: '1:1';
  }
}

.cnImage {
  border-radius: 5px;
}

.extraSaleTag {
  position: absolute;
  top: var(--space-n);
  left: var(--space-n);
  z-index: 3;

  &.bottomPlacement {
    top: unset;
    bottom: -16px;
    left: var(--space-s);
  }
}

.comingSoonTab {
  position: absolute;
  bottom: var(--space-s-neg);
  left: var(--space-s);
  z-index: 3;
}

.actions {
  position: absolute;
  top: var(--space-n);
  right: var(--space-n);
  z-index: 2;
}

.tags {
  position: absolute;
  right: var(--space-s);
  bottom: var(--space-m-neg);
  z-index: 2;
}

.info {
  margin-top: var(--space-xxl);
}

.name {
  display: block;
  max-width: 100%;

  overflow: hidden;

  color: var(--color-black);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrapperPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
}

.price {
  display: grid;
  grid-auto-flow: column;
  gap: var(--space-xs);
  align-items: baseline;
}

.actualPrice {
  font-weight: 500;
  font-size: var(--size-m);
  line-height: 1.5;
}

.discount {
  align-self: center;

  font-size: 13px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.iconBasket {
  width: 20px;
  height: 20px;
}

.parameters {
  display: flex;
  flex-direction: column;

  margin-top: var(--space-n);
}

.sizes {
  margin-top: var(--space-n);
}

.hiddenCharacteristics {
  display: none;
}

@media (--desktop) {
  .tags {
    &.hidden {
      display: none;
    }
  }
}

@media (--mobile-m) {
  .wrapperPrice {
    margin-top: var(--space-xs);
    margin-right: var(--space-s);
  }

  .yandexCreditTab {
    background-color: var(--color-white);
  }

  .soon {
    width: 100%;
  }

  .fullSpace {
    margin-right: 0;
  }

  .longPrice {
    .price {
      grid-template-areas:
        'expired discount'
        'actual actual';
      justify-items: start;
      row-gap: 0;
    }

    .actualPrice {
      grid-area: actual;
    }

    .expiredPrice {
      grid-area: expired;
    }

    .discount {
      grid-area: discount;
    }
  }

  .button {
    width: 40px;
    height: 40px;
  }
}

@media (--mobile) {
  .wrapperPrice {
    margin-right: 0;
  }
}
