.banner {
  position: relative;

  width: 100%;
  height: 600px;

  color: #000;

  &.inversed {
    color: #fff;
  }
}

.imageContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  height: 100%;
}

.container {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  height: 100%;
  padding: 40px 220px;
}

.title {
  margin-top: auto;
  margin-bottom: var(--space-xl);

  font-weight: 500;
  font-size: 70px;
  font-family: var(--font-main), sans-serif;
  line-height: 1.5;
}

.subtitle {
  width: 530px;
  margin-bottom: auto;

  font-weight: 400;
  font-size: var(--size-b);
  font-family: var(--font-main), sans-serif;
}

.condition {
  font-size: 12px;

  opacity: 0.7;
}

@media (--desktop-m) {
  .banner {
    height: 425px;
  }

  .image {
    object-position: 60%;
  }

  .container {
    padding: 30px 40px;
  }

  .title {
    margin-bottom: 25px;

    font-size: var(--size-xxb);
  }

  .subtitle {
    width: 420px;

    font-size: var(--size-xl);
  }
}

@media (--desktop) {
  .banner {
    height: 320px;
  }

  .image {
    object-position: 50%;
  }

  .title {
    margin-bottom: 20px;

    font-size: var(--size-xxl);
  }

  .subtitle {
    width: 360px;

    font-size: var(--size-l);
  }
}

@media (--mobile-m) {
  .banner {
    height: 240px;
  }

  .container {
    padding: 20px 10px;
  }

  .subtitle {
    width: 300px;

    font-size: var(--size-m);
  }
}

@media (--mobile) {
  .container {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .banner {
    height: 500px;
  }

  .title {
    margin-top: 0;
    margin-bottom: 15px;
  }
}
