.page {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-e);
}

.saleBanners {
  margin-bottom: var(--space-xxe);
}

.divider {
  composes: main from 'containers';

  &::before {
    display: block;
    margin: var(--space-e) 0;

    border-bottom: 1px solid var(--color-medium-gray);

    content: '';
  }
}

.footerText {
  composes: main from 'containers';

  margin-top: var(--space-e);
}

@media (--desktop-m) {
  .page {
    padding-bottom: var(--space-xb);
  }

  .saleBanners {
    margin-bottom: var(--space-e);
  }

  .divider::before {
    margin: var(--space-xb) 0;
  }

  .footerText {
    margin-top: var(--space-xb);
  }
}

@media (--desktop) {
  .saleBanners {
    margin-bottom: var(--space-xb);
  }
}

@media (--mobile-m) {
  .page {
    padding-top: var(--space-l);
  }

  .divider::before {
    margin: var(--space-b) 0;
  }
}
