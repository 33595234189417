.section {
  margin-top: var(--space-e);
}

.review {
  box-sizing: border-box;
  width: calc(100% / 4);
  height: 535.25px;
}

@media (--desktop-m) {
  .section {
    margin-top: var(--space-xb);
  }

  .review {
    width: calc(100% / 3);
  }
}

@media (--desktop) {
  .review {
    width: calc(100% / 2);
  }
}

@media (--mobile-m) {
  .section {
    margin-top: var(--space-b);
  }

  .galleryWrapper {
    margin-top: var(--space-m);
  }

  .review {
    box-sizing: content-box;
    width: 300px;
    height: 425.5px;
  }
}

@media (--mobile-xxs) {
  .review {
    box-sizing: content-box;
    width: 275px;
    height: 434px;
  }
}
