.banner {
  position: relative;

  box-sizing: border-box;

  width: 100%;
  height: 600px;
  padding: var(--space-l) var(--space-xxb);

  color: #000;
}

.image {
  position: absolute;
  inset: 0;

  height: 100%;
}

.container {
  position: relative;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
  height: 100%;

  text-align: left;
}

.title {
  margin-bottom: var(--space-b);

  color: #c76734;
  font-size: var(--size-xxb);
  font-family: var(--font-anton);
  line-height: 1;
}

.promocode {
  margin-bottom: 20px;

  font-weight: 500;
}

.promocode,
.subtitle {
  max-width: 720px;

  font-size: var(--size-xl);
  line-height: 1.5;
}

@media (--desktop-m) {
  .banner {
    height: 425px;
    padding: var(--space-l) var(--space-b);
  }

  .title {
    margin-bottom: var(--space-xxl);

    font-size: 40px;
  }

  .promocode,
  .subtitle {
    max-width: 540px;

    font-size: var(--size-m);
  }
}

@media (--desktop) {
  .banner {
    height: 320px;
    padding: var(--space-n) var(--space-xxl);
  }

  .title {
    font-size: var(--size-xxl);
  }

  .promocode,
  .subtitle {
    max-width: 450px;
  }
}

@media (--mobile-m) {
  .banner {
    height: 300px;
    padding: var(--space-n) var(--space-s);
  }

  .title {
    width: min-content;

    font-size: var(--size-xl);
    line-height: 1.5;
  }

  .promocode,
  .subtitle {
    max-width: 400px;

    font-size: var(--size-n);
  }
}

@media (--mobile) {
  .banner {
    height: 500px;
    padding: var(--space-m) var(--space-s);
  }

  .container {
    align-items: center;
    justify-content: flex-end;

    text-align: center;
  }

  .promocode,
  .subtitle {
    max-width: 480px;
  }

  .subtitle {
    font-size: var(--size-s);
  }

  .title {
    margin-bottom: var(--space-s);
  }

  .promocode {
    margin-top: 0;
    margin-bottom: 15px;
  }
}
