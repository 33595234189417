.reviewWrapper {
  height: 100%;

  border: 1px solid var(--color-medium-gray);
  border-radius: 15px;
}

.review {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  box-sizing: border-box;
  height: 100%;
  padding: var(--space-l);
}

.head {
  display: flex;
  gap: var(--space-s);
}

.productImage {
  width: 90px;
  height: 56px;
}

.productInfo {
  display: flex;
  flex-direction: column;
  gap: var(--space-xxs);
  justify-content: center;
  width: calc(100% - 90px - var(--space-s));
}

.productName {
  overflow: hidden;

  font-weight: 500;
  font-size: var(--size-n);
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.containerPrices {
  display: flex;
  align-items: center;
}

.actualPrice {
  font-weight: 500;
  font-size: var(--size-m);
  line-height: 1.5;

  &.promo {
    color: var(--color-red);
  }
}

.expiredPrice {
  margin-left: var(--space-xs);

  color: #989898 !important;
  font-weight: 400;
  font-size: var(--size-n);
  line-height: 1.5;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  height: 100%;
}

.textWrapper {
  flex: 1 1 0;
  min-height: 0;

  font-size: var(--size-n);
  line-height: 1.5;
}

.text {
  display: -webkit-box;
  height: var(--height);
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--lines-count);
  line-clamp: var(--lines-count);
}

.reviewGallery {
  gap: var(--space-n);
}

.name {
  font-weight: 500;
  font-size: var(--size-n);
}

@media (--mobile-m) {
  .review {
    gap: var(--space-m);
    padding: var(--space-s);
  }

  .productName {
    font-size: var(--size-s);
  }

  .actualPrice {
    font-size: var(--size-n);
  }

  .expiredPrice {
    font-size: var(--size-s);
  }

  .content {
    gap: var(--space-n);
  }

  .textWrapper {
    font-size: var(--size-s);
    line-height: 1.5;
  }

  .reviewGallery {
    gap: var(--space-s);
  }

  .name {
    font-size: var(--size-s);
  }
}
