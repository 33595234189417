.wrapper :last-child {
  margin-bottom: 0;
}

.container {
  color: var(--color-black);
  word-wrap: break-word;
}

.imageContainer {
  position: relative;

  margin-bottom: var(--space-n);
}

.image {
  aspect-ratio: '1.5:1';
}

.discount {
  position: absolute;
  top: var(--space-n);
  right: var(--space-n);

  font-size: var(--size-n);
}

.title {
  margin-bottom: var(--space-xxs);

  font-weight: 500;
  font-size: var(--size-xxl);
  font-family: var(--hero);
  line-height: 1.5;
}

.subtitle {
  font-size: var(--size-m);
  line-height: 1.5;
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-xl);
  }

  .subtitle {
    font-size: var(--size-n);
  }
}

@media (--mobile-m) {
  .title {
    font-size: var(--size-l);
  }

  .subtitle {
    font-size: var(--size-s);
  }
}
