.preview {
  --proportion: var(--image-proportion-landscape);

  position: relative;

  &:after {
    display: block;
    width: 100%;
    padding-bottom: var(--proportion);

    content: '';
  }
}

.preview.landscape {
  --proportion: var(--image-proportion-landscape);
}
.preview.portrait {
  --proportion: var(--image-proportion-portrait);
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.image {
  padding-bottom: 0;
  overflow: hidden;

  border-radius: 5px;
}
