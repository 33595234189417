.banner {
  position: relative;

  width: 100%;
  height: 750px;

  color: #000;

  &.inversed {
    color: #fff;
  }

  &.inversedLogo {
    .logo {
      filter: invert(1);
    }
  }
}

.imageContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100%;
}

.container {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  height: 100%;
  padding: 40px 220px;
}

.logo {
  margin-bottom: 35px;
}

.period {
  margin-top: auto;
  margin-bottom: 35px;

  font-weight: 500;
  font-size: 22px;
}

.title {
  margin-top: auto;
  margin-bottom: var(--space-xl);

  font-weight: 500;
  font-size: 70px;
  font-family: var(--font-main), sans-serif;
  line-height: 1.5;
}

.logoTitle {
  margin-bottom: auto;

  font-weight: 500;
  font-size: 70px;
  font-family: var(--font-main), sans-serif;
  line-height: 1.5;
}

.subtitle {
  margin-bottom: auto;

  font-weight: 400;
  font-size: var(--size-b);
  font-family: var(--font-main), sans-serif;
}

.condition {
  max-width: 1468px;

  font-size: 12px;

  opacity: 0.7;
}

/* + Content x-y alignment */
.centerCenter {
  align-items: center;

  text-align: center;

  & > :first-child {
    margin-top: auto;
  }
}

.centerTop {
  align-items: center;
  padding-top: 60px;

  text-align: center;

  & > :first-child {
    margin-top: 0px;
  }
}

.leftCenter {
  align-items: flex-start;
  padding-left: 221px;

  text-align: left;

  & > :first-child {
    margin-top: auto;
  }
}

.leftTop {
  align-items: flex-start;
  padding-left: 221px;

  text-align: left;

  & > :first-child {
    margin-top: 60px;
  }
}
/* - Content x-y alignment */

@media (--desktop-m) {
  .period {
    margin-bottom: 25px;

    font-size: 18px;
  }

  .banner {
    height: 534px;
  }

  .image {
    object-position: 60%;
  }

  .container {
    padding: 30px 40px;
  }

  .title,
  .logoTitle {
    margin-bottom: 25px;

    font-size: var(--size-xxb);
  }

  .logoTitle {
    margin-bottom: auto;
  }

  .subtitle {
    font-size: var(--size-xl);
  }

  .condition {
    font-size: 10px;
  }

  .logo {
    margin-bottom: 25px;
  }
}

@media (--desktop) {
  .period {
    margin-bottom: 15px;

    font-size: 16px;
  }
  .image {
    object-position: 50%;
  }

  .title,
  .logoTitle {
    margin-bottom: 20px;

    font-size: var(--size-xxl);
  }

  .logoTitle {
    margin-bottom: auto;
  }

  .subtitle {
    font-size: var(--size-l);
  }
}

@media (--mobile-m) {
  .banner {
    height: 364px;
  }

  .container {
    padding: 20px 15px;
  }

  .subtitle {
    font-size: var(--size-m);
  }

  .condition {
    font-size: 9px;
  }

  .logo {
    margin-bottom: 15px;
  }
}

@media (--mobile) {
  .leftCenter {
    & > :first-child {
      margin-top: 0;
    }
  }

  .period {
    margin-top: 0;
  }

  .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .banner {
    height: 580px;
  }

  .logo {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .title,
  .logoTitle {
    margin-top: 0;
    margin-bottom: 15px;
  }

  .condition {
    margin-top: auto;
  }

  .centerTop {
    align-items: start;

    text-align: left;

    & > :first-child {
      margin-bottom: 0px;
    }
  }

  .centerTop {
    & > .logo {
      margin-bottom: 25px;
    }
  }
}
